@import '../../../../scss/theme-bootstrap';

.loyalty_cancellation_popup,
.loyalty-cancellation-confirm-overlay {
  @include breakpoint($medium-up) {
    padding: 30px;
    padding-bottom: 0;
  }
  .loyalty--popover {
    &__title {
      font-family: $font--malone;
      font-size: 23px;
      text-align: $ldirection;
    }
    &__content {
      text-align: center;
      &__checkbox {
        font-size: 13px;
        padding-#{$ldirection}: 5px;
        display: inline-block;
        vertical-align: top;
        width: 82%;
        margin-bottom: 20px;
        padding-top: 9px;
        line-height: 1.2;
        @include breakpoint($medium-up) {
          margin-bottom: 10px;
          width: 91%;
        }
      }
    }
    &__header {
      @include typestyle--h8;
      text-align: $ldirection;
      font-size: 23px;
      margin-bottom: 20px;
      font-family: $font--malone;
      letter-spacing: normal;
      width: 90%;
      line-height: 1;
      @include breakpoint($medium-up) {
        font-size: 30px;
        margin-bottom: 5px;
        width: 78%;
      }
    }
    &__sub--header {
      @include typestyle--h8;
      font-size: 13px;
      border-bottom: 0;
      font-family: $font--malone;
      letter-spacing: normal;
      margin-top: 15px;
      text-align: $ldirection;
      text-transform: none;
    }
    &__form {
      @include typestyle--h7;
      text-align: $ldirection;
      margin-top: 5px;
    }
    &__contact--us {
      margin-top: 20px;
      font-size: 10px;
      line-height: 1.5;
      color: $color-medium-gray;
      &__link {
        cursor: pointer;
        color: $color-medium-gray;
        text-decoration: underline;
      }
    }
    &__buttons {
      margin-top: 20px;
      &--link {
        padding: 0.75em 2em;
        display: block;
        border: 1px solid $color-black;
        margin: 0 auto;
        &.button--disabled {
          border: none;
        }
      }
      &--retain {
        margin-top: 10px;
        border: none;
        color: $color-medium-gray;
        text-decoration: underline;
        background: none;
        &:hover {
          background: none;
          color: $color-medium-gray;
        }
      }
    }
    &__loyalty {
      &_rejoin {
        &-text {
          text-align: center;
          padding-top: 20px;
        }
      }
      &_cancel {
        &--option {
          color: $color-medium-gray;
          input[type='checkbox']:checked + label {
            color: $color-black;
            &::before {
              background-color: $color-black;
            }
          }
        }
      }
    }
  }
}

.loyalty-cancel-overlay {
  #cboxClose {
    top: 38px;
    #{$rdirection}: 32px;
    font-size: 16px;
    color: $color-black;
    text-indent: 999px;
  }
}
